import React from "react";
import Btn from "../components/Btn";
import "../css/styles-components/Intro-section.css";
import "../css/styles-global/Global.css";

function IntroSection() {
  return (
    <>
      <a name="intro-section" style={{ visibilty: "hidden" }}></a>
      <section className="intro-section">
        <div className="text-section">
          <h2>Our mission</h2>
          <p>
            Our community dreams of a future where environmentally friendly
            cryptocurrency are the norm.
          </p>
          <p>
            We are enthusiasts who want to create the most expensive
            cryptocurrency where the price is not 0.00000001$ like most meme
            coins.
          </p>
          <Btn value="Buy ONE-COIN" />
        </div>
        <div className="img-section">
          <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.wallpapers13.com%2Fwp-content%2Fuploads%2F2015%2F12%2FEarth-from-space-8-cool-wallpaper-1920x1440.png&f=1&nofb=1"></img>
        </div>
      </section>
    </>
  );
}
export default IntroSection;
