import React from "react";
import Btn from "../components/Btn";
import "../css/styles-components/Roadmap-section.css";
import "../css/styles-global/Global.css";

function RoadmapSection() {
  return (
    <section className="roadmap-section">
      <h2>Roadmap</h2>
      <div id="timeline-content">
        <ul className="timeline">
          <li className="event" data-date="Feb 2022">
            <h3>Presence in social media</h3>
            <p>on Discord, Reddit, Twitter, Instagram</p>
          </li>
          <li className="event" data-date="Mar 2022">
            <h3>Creation of the coin</h3>
            <p>
              We try to do this as early as possible in combination with high
              shares in liquidity pools
            </p>
          </li>
          <li className="event" id="date" data-date="Mar 2022">
            <h3>First coin drops</h3>
            <p>on the social networks and the first favorable sales </p>
          </li>
          <li className="event" id="date" data-date="Mar 2022">
            <h3>Access for all</h3>
            <p>Become the most expensive cryptocurrency</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

/* class FaqSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          title: "Lorem ipsum dolor sit amet",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
          date: "feb 2",
        },
        {
          title: "Lorem ipsum dolor sit amet",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
          date: "feb 2",
        },
      ],
    };
  }
  render() {
    return (
      <section className="roadmap-section">
        <h2>Roadmap</h2>
        <div>
          {this.state.data.map((elem, index) => (
            <>
              <div className="record">
                <h4>elem.title</h4>
              </div>
              {index === this.state.data.length ? (
                <div className={`line ${index % 2 ? "right" : "left"}`}></div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </section>
    );
  }
} */
export default RoadmapSection;
