import React from "react";
import "../css/styles-global/Global.css";
import "../css/styles-components/Btn.css";

function Btn({ href, value, onclick, primary }) {
  if (href) {
    return (
      <a className={`btn ${primary ? "primary" : "secondary"}`} href={href}>
        {value}
      </a>
    );
  } else {
    return (
      <button
        className={`btn ${primary ? "primary" : "secondary"}`}
        onClick={onclick}
      >
        {value}
      </button>
    );
  }
}

export default Btn;
