import React from "react";
import Btn from "./Btn";
import "../css/styles-components/Values-section.css";
import "../css/styles-global/Global.css";

function Value({ img, headline, text, btn }) {
  return (
    <div className="value">
      <div className="img-wrapper">
        <img src={img} />
      </div>
      <div className="text-wrapper">
        <h3>{headline}</h3>
        <p>{text}</p>
        <Btn value={btn} />
      </div>
    </div>
  );
}

function ValuesSection() {
  return (
    <section className="values-section">
      <Value
        img="https://solana.com/favicon.ico"
        headline="Lorem ipsum"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
        btn="Lorem ipsum"
      />
      <Value
        img="https://solana.com/favicon.ico"
        headline="Lorem ipsum"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
        btn="Lorem ipsum"
      />
      <Value
        img="https://solana.com/favicon.ico"
        headline="Lorem ipsum"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
        btn="Lorem ipsum"
      />
    </section>
  );
}
export default ValuesSection;
