import React from "react";
import Btn from "./Btn";
import "../css/styles-components/Faq-section.css";
import "../css/styles-global/Global.css";

class FaqSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      data: [
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
        {
          title: "Why is the F&Q not ready",
          text: `there are no questions`,
        },
      ],
    };
  }
  render() {
    return (
      <section className="faq-section">
        <h2>F&A Section</h2>
        {this.state.data.map((elem, index) => (
          <div key={index}>
            <div
              onClick={() =>
                this.setState({
                  open: this.state.open === index ? null : index,
                })
              }
              className={`header-wrapper ${
                this.state.open === index ? "open" : "closed"
              }`}
            >
              <h4>{elem.title}</h4>
              <div className="icon-wrapper">
                <div className="left" />
                <div className="right" />
              </div>
            </div>
            <div
              className={`body-wrapper ${
                this.state.open === index ? "open" : "closed"
              }`}
            >
              <p>{elem.text}</p>
            </div>
          </div>
        ))}
      </section>
    );
  }
}
export default FaqSection;
