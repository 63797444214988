import "../css/styles-pages/App.css";
import Nav from "../components/Nav";
import LandingSelection from "../components/Landing-section";
import IntroSection from "../components/Intro-section";
import RoadmapSection from "../components/Roadmap-section";
import SocialMediaSection from "../components/Social-media-section";
import Footer from "../components/Footer";
import TokenomicsSection from "../components/Tokenomics-section";
import DistributionSection from "../components/Distribution-section";
import FaqSection from "../components/Faq-section";
import ValuesSection from "../components/Values-section";
function App() {
  return (
    <>
      <Nav logo="https://solana.com/favicon.ico" />
      <LandingSelection />
      <IntroSection />
      <ValuesSection />
      <TokenomicsSection />
      <DistributionSection />
      <RoadmapSection />
      <FaqSection />
      <SocialMediaSection />
      <Footer />
    </>
  );
}

export default App;
