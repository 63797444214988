import React from "react";
import Btn from "./Btn";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "../css/styles-components/Distribution-section.css";
import "../css/styles-global/Global.css";

function DistributionSection() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: ["Team", "Marketing", "Early Bird Sales", "Community"],
    datasets: [
      {
        label: "# of Votes",
        data: [0.05, 0.15, 0.35, 0.45],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <section className="distribution-section">
      <h2>Distribution of ONECOIN</h2>
      <div className="content-wrapper">
        <div className="text-wrapper">
          <p>
            With the distribution we have put a lot of emphasis on the fact that
            almost everything goes to the community.
          </p>
          <p>
            We want as quickly as possible high attention by giving away large
            quantities and aggressive marketing which should be enabled by early
            sales with 15% of the coin.
          </p>
        </div>
        <div className="chart-wrapper">
          <Doughnut data={data} />
        </div>
      </div>
    </section>
  );
}
export default DistributionSection;
