import React from "react";
import Btn from "./Btn";
import "../css/styles-components/Nav.css";
import "../css/styles-global/Global.css";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  toggle() {
    this.setState({ open: !this.state.open });
  }
  render() {
    return (
      <nav>
        <div
          className={`mobile-menu${this.state.open ? " open" : " closed"}`}
          onClick={this.toggle.bind(this)}
        />
        <a className="logo" href="/">
          <img src={this.props.logo} alt="logo" />
        </a>
        <div className={`links${this.state.open ? " open" : " closed"}`}>
          <img className="logo-menu" src={this.props.logo} alt="logo" />

          <a onClick={this.toggle.bind(this)} href="#intro-section">
            intro-section
          </a>
          <a onClick={this.toggle.bind(this)} href="/Lorem2">
            Lorem2
          </a>
          <a onClick={this.toggle.bind(this)} href="/Lorem3">
            Lorem3
          </a>
          <a onClick={this.toggle.bind(this)} href="/Lorem4">
            Lorem4
          </a>
          <a onClick={this.toggle.bind(this)} href="/Lorem5">
            Lorem5
          </a>
          <a onClick={this.toggle.bind(this)} href="/Lorem6">
            Lorem6
          </a>
          <Btn className="btn" value="Buy ONECOIN" primary={false} />
        </div>
        <div
          className={`hamburger${this.state.open ? " open" : " closed"}`}
          onClick={this.toggle.bind(this)}
        >
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </nav>
    );
  }
}

export default Nav;
