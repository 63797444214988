import React from "react";
import Btn from "../components/Btn";
import "../css/styles-components/Footer.css";
import "../css/styles-global/Global.css";

function Footer() {
  return (
    <footer>
      <div className="main-footer">
        <a href="https://google.com">medium</a>
        <a href="https://google.com">coin-gecko</a>
        <a href="https://google.com">solana-scan</a>
        <a href="https://google.com">Lorem</a>
      </div>
    </footer>
  );
}
export default Footer;
