import React from "react";
import Btn from "./Btn";
import "../css/styles-components/Tokenomics-section.css";
import "../css/styles-global/Global.css";

function TokenomicsSection() {
  return (
    <section className="tokenomics-section">
      <h2>Tokenomics</h2>
      <div className="data-wrapper">
        <div>
          <h3 className="coin-name">ONECOIN</h3>
          <p>Coin Symbol</p>
        </div>
        <div>
          <h3 className="one">1</h3>
          <p>Total Supply</p>
        </div>
        <div>
          <img src="/img/solanaWord.svg" />
          <p>Network</p>
        </div>
      </div>
    </section>
  );
}
export default TokenomicsSection;
