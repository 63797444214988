import React from "react";
import Btn from "./Btn";
import "../css/styles-components/Social-media-section.css";
import "../css/styles-global/Global.css";

function SocialMediaSection() {
  return (
    <section className="social-media-section">
      <h3>Join our community at</h3>
      {/*       <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore.
            </p> */}
      <div>
        <a href="https://twitter.com">
          <img src="/img/twitter.svg" />
          <p>
            <small>twitter</small>
          </p>
        </a>
        <a href="https://coinmarketcap.com">
          <img src="/img/coinmarketcap.svg" />
          <p>
            <small>coinmarketcap</small>
          </p>
        </a>
        <a href="https://discord.com">
          <img src="/img/discord.svg" />
          <p>
            <small>discord</small>
          </p>
        </a>
        <a href="https://reddit.com">
          <img src="/img/reddit.svg" />
          <p>
            <small>reddit</small>
          </p>
        </a>
        <a href="https://instagram.com">
          <img src="/img/instagram.png" />
          <p>
            <small>instgram</small>
          </p>
        </a>
      </div>
    </section>
  );
}
export default SocialMediaSection;
