import React from "react";
import Btn from "../components/Btn";
import "../css/styles-components/Landing-section.css";
import "../css/styles-global/Global.css";

function LandingSection() {
  return (
    <section className="landing-section">
      <div className="text-section">
        <h1>
          <span>ONECOIN</span>, soon to be the most expensive cryptocurrency.
        </h1>
        <p>
          Be there to raise awareness with ONECOIN for our ONE Earth on Climate
          Neutral Cryptocurrencies.
        </p>
        <div className="actions">
          <Btn value="Join our community" primary={true} />
          <Btn value="buy ONECOIN" primary={false} />
        </div>
      </div>
      <div className="img-section">
        <img src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fechomon.co.uk%2Fwp-content%2Fuploads%2F2013%2F03%2FPhoto-of-Earth-From-Space.jpg&f=1&nofb=1"></img>
      </div>
    </section>
  );
}
export default LandingSection;
